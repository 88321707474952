import React from 'react';
import ReactDOM from 'react-dom';
import './assets/scss/index.scss';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import GA4React, { useGA4React } from "ga-4-react";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_SENTRY_ENV,
  release: process.env.REACT_APP_SENTRY_RELEASE,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

if (process.env.REACT_APP_GA_ID) {
  try {
    setTimeout(_ => {
      const ga4react = new GA4React(process.env.REACT_APP_GA_ID);
      ga4react.initialize().catch(err => console.error(err));
    }, 40000);
  } catch (err) {
    console.error(err);
  }
  // console.log('env ada')
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
